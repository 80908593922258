<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ data.name ? "" + data.name : "Создание регулярной задачи" }}
      </template>
      <v-row>
        <v-col cols="12">
          <a-form-model
            v-model="data"
            :model="model"
            :errors="errors"
            @validate="validate($event)"
          />
        </v-col>
        <v-col cols="3">
          <v-radio-group v-model="cronType">
            <v-radio
              v-for="el in cronHeader"
              :key="el.value"
              :label="el.text"
              :value="el.value"
            />
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="12" md="9">
          <a-cron-weekly
            v-if="cronType == 1"
            :value="data.cron_data"
            @change-val="changeVal"
          />
          <a-cron-monthly
            v-if="cronType == 2"
            :value="data.cron_data"
            @change-val="changeVal"
          />
        </v-col>
        <v-divider />

        <v-expansion-panels v-model="panel" v-if="loaded">
          <v-expansion-panel>
            <v-expansion-panel-header
              >Настройки задачи:</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-col cols="12">
                <a-form-model
                  v-model="data"
                  :model="modelTask"
                  :errors="errors"
                  @validate="validate($event)"
                />
                <a-input-selectuser
                  class="pt-2"
                  :model="{
                    name: 'watchers',
                    title: 'Наблюдатели',
                    type: 'selectuser',
                    chips: true,
                  }"
                  v-model="data.watchers"
                  :config="{ dense: true }"
                  @input="validate($event)"
                  :error="errors['task_data.watchers']"
                />
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save
          @click="submit_()"
          v-if="
            getAccess('task.edit', { users: [data.user_id, user_id] }) || !id
          "
        />
        <a-btn-delete
          v-if="id && data.createdby_id == $root.profile.id"
          @click="removeDialogShow = true"
        />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog
      v-model="removeDialogShow"
      @remove="remove(api, id)"
      :title="removeTitle"
    />
    <su-Data
      ref="suEdit"
      v-model="suEditShow"
      :api="api"
      :id="id"
      :activate="value"
      :dataEdit="data"
      @show="suEditShow = true"
      v-if="getAccess('suData')"
    />
  </div>
</template>
 
<script>
import {
  getForm,
  submitForm,
  removeEl,
  getAccess,
} from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, getAccess],
  props: {
    value: Boolean,
    id: Number,
    task: Object,
  },
  data() {
    return {
      panel: this.task ? null : 0,
      m: JSON.parse(
        JSON.stringify(this.$store.getters["config/get"].models.cron)
      ),
      mTask: JSON.parse(
        JSON.stringify(this.$store.getters["config/get"].models.tasks)
      ),
      saving: false,
      dataTask: {},
      errorsTask: {},
      validatorsTask: {},
      cronType: null,
      cronHeader: [
        { value: 1, text: "Неделя" },
        { value: 2, text: "Месяц" },
      ],
      api: "/mechti/cron_tasks",
      user_id: null,
      defaults: {
        date_event: new Date(),
        user_id: this.$root.profile.id,
        is_important: 2,
        status: 0,
      },
      suEditShow: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить это расписание?",
      isChanged: false,
      loaded: false,
    };
  },
  computed: {
    model() {
      let model = JSON.parse(JSON.stringify(this.m.form));
      model.forEach((el) => {
        if (el?.sourceList) el.options = this.m[el.sourceList];
      });
      return model;
    },
    modelTask() {
      let model = [];
      for (const el of this.mTask.form) {
        if (this.m.taskData.includes(el.name)) {
          el.name = "task_data." + el.name;
          model.push(el);
        }
      }
      model.forEach((el) => {
        if (el?.sourceList) el.options = this.mTask[el.sourceList];
      });
      return model;
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
        this.resetTaskForm();
        this.user_id = this.data.user_id;
        if (this.id) {
        } else {
          this.data.nextevent = new Date().date;
        }
      }
    },
  },
  async created() {
    //this.resetTaskForm();
    this.fillForm();
    await this.fetchData(this.id || 0);
    //    this.fillTaskForm();
    this.user_id = this.data.user_id;
    if (this.id) {
      if (this.data.cron_data.dow) {
        this.cronType = 1;
      }
      if (this.data.cron_data.mon) {
        this.cronType = 2;
      }
    } else {
      if (this.task && 1) {
        for (const name in this.task) {
          if (this.m.taskData.includes(name)) {
            console.log("fill task ", name, this.task[name]);
            this.data.task_data[name] = this.task[name];
          }
        }
      }

      this.data.nextevent = new Date().date;
    }
    this.loaded = true;
  },
  methods: {
    fillForm() {
      this.fillFormFromModel(this.model);
      this.fillFormFromModel(this.modelTask);
    },
    async submit_() {
      if (this.saving) return false;
      this.saving = true;
      let cron = JSON.parse(JSON.stringify(this.data));
      if (this.cronType == 1) {
        if (!cron.cron_data.dow || !cron.cron_data.dow.length) {
          this.$root.$emit("show-info", {
            text: "Не выбраны дни недели для расписания",
            type: "error",
          });
          return;
        }
        cron.cron_data.mon = null;
        cron.cron_data.dom = null;
      } else if (this.cronType == 2) {
        if (!cron.cron_data.mon || !cron.cron_data.mon.length) {
          this.$root.$emit("show-info", {
            text: "Не выбраны месяцы",
            type: "error",
          });
          return;
        }
        if (!cron.cron_data.dom || !cron.cron_data.dom.length) {
          this.$root.$emit("show-info", {
            text: "Не выбраны числа",
            type: "error",
          });
          return;
        }
        cron.cron_data.dow = null;
      } else {
        this.$root.$emit("show-info", {
          text: "Не настроено расписание",
          type: "error",
        });
        return;
      }
      cron.cron_data.l = 360;
      cron.cron_data.m = 1;
      cron.cron_data.h = 0;
      // cron.task_data = {};
      /*
        for (const key of this.m.taskData) {
          if (this.task?.[key]) {
            cron.task_data[key] = this.task[key];
          }
        }
*/
      //     cron.task_data = this.dataTask;
      cron.task_data.params = {};
      cron.task_data.status = 0;
      this.data = cron;
      // console.log(this.data);
      //this.validateAll(this.data);
      console.log("ща как запишу ", this.data);
      //this.saving = false;

      if (await this.submit()) {
      } else {
        this.saving = false;
      }
    },
    changeVal(val) {
      this.data.cron_data[val.name] = val.value;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      this.saving = false;
      if (status) {
        this.$emit("input");
        if (this.task) {
          //Так как расписание делается на основании задачи, то надо расписание с задачей
          let d = { id: data.id, task_data: this.data.task_data };
          d.task_data.params.schedule_id = this.data.id;
          this.loading = true;
          this.$axios.post(this.api, d);
          if (data.id) this.$emit("link-schedule", data.id);
        }
      }
    },
    resetTaskForm() {
      (this.dataTask = {}), (this.errorsTask = {}), (this.validatorsTask = {});
    },
    fillTaskForm() {
      this.fillTaskModel(this.modelTask);
    },
    fillTaskModel(model) {
      for (let el of model) {
        if (el?.calculated !== true && !el?.json) {
          if (!this.dataTask[el.name]) {
            this.dataTask = Object.assign({}, this.dataTask, {
              [el.name]: this.data.task_data?.[el.name] || null,
            });

            // this.$set(this.dataTask, el.name, this.getDefaultValue(el));
            this.$set(this.errorsTask, el.name, null);
          }
          if (el.validator) {
            let name = el.name;
            this.$set(this.validatorsTask, name, el.validator);
          }
        }
      }
    },
  },
};
</script>
<style  lang="sass">
</style>